import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import SymptomOverviewSection from "./SymptomOverviewSection.web";
import ItemWithLetter from "./ItemWithLetter.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import ReactHtmlParser from "./ReactHtmlParser";

import { IContentData, IImage, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554'
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  fontSize: '22px', 
  fontWeight: '400',
});

export interface ISectionData  {
  id?: number;
  name: string;
  definition: string;
  images: IImage[];
  children? : { definition: string; images: IImage[] }[];
}

const SymptomARD = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderSectionItem = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const itemWithChildrenList = Array.from(doc.querySelectorAll('li:has(div)')).map(li => {
      return {
        letter: (li.children[0] && li.children[0]?.tagName === 'DIV' && li.children[0]?.textContent) || '',
        paras: li.children[1] && li.children[1]?.tagName === 'UL' ? 
          Array.from(li.children[1]?.children).map(child => child.innerHTML) : [],
      }
    })
    if (itemWithChildrenList.length > 0) {
      return itemWithChildrenList.map((item, index) => <ItemWithLetter key={index} {...item} content={item.paras} />)
    }
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item)}</PlainTextItem>))
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Anxiety-Restlessness' onNavigate={onNavigate} />
      <SectionListLayout>
        <SymptomOverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title ? <SectionTitleContainer>{ReactHtmlParser(title?.title)}</SectionTitleContainer> : <></>} 
          shortDesc={title && <LightSectionSubtitle>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
        />
        {sectionList && sectionList.length > 0 && sectionList.map(section => 
          <CustomSectionContainer 
            withHeading={!!section.name}
            heading={<LightSectionTitle>{ReactHtmlParser(section.name)}</LightSectionTitle>}
          >
            {section?.children && section.children.length > 0 ? 
              <Grid container spacing={2.5}>
                {section?.children.map((child, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <ItemWithImage key={`grid-item-${index}`} image={child.images[0].url} text={ReactHtmlParser(child.definition)} />
                  </Grid>
                ))}
              </Grid> : 
              renderSectionItem(section.definition)}
          </CustomSectionContainer>)}
      </SectionListLayout>
    </>
  );
}

export default SymptomARD;
