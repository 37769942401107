import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';

import ContentBreadcrumbs from './ContentBreadcrumb.web';
import SymptomOverviewSection from './SymptomOverviewSection.web';
import PlainTextItem from './PlanTextItem.web';
import ItemWithImage from './ItemWithImage.web';
import { OverviewAnnotation } from './MethodOverviewSection.web';
import { CustomSectionContainer, SectionListLayout, SubcatContainer } from './CustomContainer.web';
import { ISectionData } from './SymptomARD.web';
import ReactHtmlParser from './ReactHtmlParser';

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  fontSize: '22px', 
  fontWeight: '400',
});

function Psychotropic({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]); 

  const renderSectionItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li')).map(li => li.innerHTML);
    return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item)}</PlainTextItem>))
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Medication/Psychotropic (Anxiety-Agitation-Restlessness)' onNavigate={onNavigate} />
      <SectionListLayout>
        <SymptomOverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && <SectionTitleContainer sx={{ fontSize: { xs: 20, lg: 26 } }}>{ReactHtmlParser(title?.title)}</SectionTitleContainer>} 
          shortDesc={title && <LightSectionSubtitle sx={{ fontSize: { xs: 20, lg: 22 } }}>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
          annotation={sectionList.length > 0 &&
            <OverviewAnnotation 
              title={ReactHtmlParser(sectionList[0].name)} 
              image={sectionList[0].images.length > 0 ? sectionList[0].images[0].url : ''}
              content={renderSectionItems(sectionList[0].definition)}
            />}
        />

        <CustomSectionContainer variant="dark">
          {sectionList
            .slice(1)
            .filter(section => section.id)
            .map((detail, index) => (
            <Box key={index}>
              <OverviewAnnotation 
                title={ReactHtmlParser(detail.name)}
                image={detail.images.length > 0 ? detail.images[0]?.url : ''}
                content={renderSectionItems(detail.definition)}
                sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
              />
              {index === 0 && <Divider sx={{ marginTop: 5, backgroundColor: '#586554' }} />}
            </Box>
          ))}
        </CustomSectionContainer>
        
        <CustomSectionContainer 
          containerStyle={{ paddingTop: 0, paddingBottom: { xs: 2.5, lg: 0 } }} 
          contentStyle={{ flexDirection: 'row', flexWrap: 'wrap' }} 
          variant="light"
        >
          {sectionList
            .filter(section => section?.children && section?.children.length > 0)
            .map((section, index) => 
              <SubcatContainer
                key={index}
                containerStyle={{
                  marginTop: 0,
                  marginBottom: 0,
                  borderRadius: '8px', padding: '40px 20px', 
                  backgroundColor: index === 0 ? '#F2F4F1' : '#FEF2F2',
                  border: index !== 0 ? '2px dashed #F87171' : ''
                }}
                withHeading
                heading={<LightSectionTitle sx={{ fontSize: { xs: 24, lg: 26 } }}>{ReactHtmlParser(section.name)}</LightSectionTitle>}
              >
                 <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <>
                      <Grid key={`lg-${index}`} item xs={12} sx={{ display: { xs: 'none', lg: 'block'}  }}>
                        <ItemWithImage image={child?.images[0]?.url || ''} text={ReactHtmlParser(child?.definition)} imageSize={120} />
                      </Grid>
                      <Grid key={`xs-${index}`} item xs={12} sx={{ display: { xs: 'block', lg: 'none'}  }}>
                        <ItemWithImage image={child?.images[0]?.url || ''} text={ReactHtmlParser(child?.definition)} imageSize={60} />
                      </Grid>
                    </>
                  ))}
                </Grid>
              </SubcatContainer>)}
        </CustomSectionContainer>
      </SectionListLayout>
      
    </>
  );
}

export default Psychotropic;
 