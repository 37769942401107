import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import SymptomOverviewSection from "./SymptomOverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";
import PlainTextItem from "./PlanTextItem.web";
import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const toiletManImage = require('./toilet_man.svg');
const howToTakeImage = require('./how_to_take.svg');
const whenItWorkImage = require('./when_they_work.svg');

interface TypesOpioidMedProps {
  data: IContentData, onNavigate: (to: string) => void 
}

export default function TypesOpioidMed({data, onNavigate }: TypesOpioidMedProps) {
  const renderArrowRight = (color: string) => {
    return <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.997666 19.0147C1.48767 19.5047 2.27767 19.5047 2.76767 19.0147L11.0777 10.7047C11.4677 10.3147 11.4677 9.68469 11.0777 9.29469L2.76767 0.984688C2.27767 0.494687 1.48767 0.494687 0.997666 0.984688C0.507666 1.47469 0.507666 2.26469 0.997666 2.75469L8.23767 10.0047L0.987666 17.2547C0.507666 17.7347 0.507666 18.5347 0.997666 19.0147Z" fill={color}/>
    </svg>
    
  }
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);
  console.log('data',data)
  console.log('sectionList',sectionList)

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);
  const renderMedicationImage = () => {
    return <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_19673_24033)">
    <path d="M84.0242 117.334C95.8319 117.334 105.404 107.762 105.404 95.9539C105.404 84.1462 95.8319 74.5742 84.0242 74.5742C72.2165 74.5742 62.6445 84.1462 62.6445 95.9539C62.6445 107.762 72.2165 117.334 84.0242 117.334Z" fill="#F9F3F1"/>
    <path d="M99.1406 111.076C90.7917 119.425 77.2559 119.425 68.907 111.076C68.6067 110.775 68.3159 110.466 68.0391 110.151C76.388 116.093 88.047 115.319 95.5327 107.834C103.577 99.7895 103.87 86.927 96.4099 78.5312C97.3671 79.2116 98.2819 79.9811 99.1406 80.8396C107.492 89.1888 107.492 102.727 99.1406 111.076Z" fill="#EFE2DD"/>
    <path d="M58.0893 11.3059C62.2727 3.3939 72.0778 0.370931 79.9898 4.55429L109.615 20.2178C117.527 24.4009 120.55 34.2062 116.367 42.1183C112.184 50.0303 102.378 53.0533 94.4665 48.8699L64.841 33.2064C56.929 29.0233 53.9062 19.218 58.0893 11.3059Z" fill="#FEC165"/>
    <path d="M116.366 42.1186C116.227 42.3814 116.082 42.6394 115.925 42.8928C117.677 35.8341 114.482 28.2286 107.763 24.6769L78.1398 9.01362C70.4898 4.96925 61.0735 7.66011 56.6797 14.9909C56.9893 13.7382 57.4562 12.4995 58.0871 11.3056C62.2698 3.39284 72.0755 0.371512 79.9883 4.55417L109.614 20.2177C117.525 24.4003 120.549 34.2061 116.366 42.1186Z" fill="#FDB441"/>
    <path d="M79.6523 41.0352L94.8012 12.3828L109.614 20.2145C117.526 24.3976 120.549 34.2029 116.366 42.1149C112.182 50.027 102.377 53.0499 94.4651 48.8666L79.6523 41.0352Z" fill="#6BD9E7"/>
    <path d="M116.366 42.1184C116.288 42.2662 116.206 42.4116 116.124 42.5571C116.061 42.6696 115.992 42.7823 115.924 42.8925C117.677 35.8338 114.482 28.2284 107.763 24.6766L92.5547 16.635L94.7998 12.3867L109.614 20.2172C117.525 24.4001 120.548 34.2059 116.366 42.1184Z" fill="#2ED1E2"/>
    <path d="M52.8038 45.4012C59.187 51.6745 59.2761 61.9347 53.0028 68.3179L29.513 92.2188C23.2397 98.602 12.9795 98.6911 6.59629 92.4178C0.213085 86.1445 0.124022 75.8843 6.3973 69.5011L29.8871 45.6002C36.1604 39.217 46.4206 39.1279 52.8038 45.4012Z" fill="#7ACAA6"/>
    <path d="M6.59594 92.4171C6.38383 92.2092 6.17828 91.9959 5.98047 91.7728C12.242 95.4727 20.4437 94.5888 25.7704 89.1684L49.2595 65.2692C55.3246 59.0974 55.4425 49.305 49.6764 42.9961C50.788 43.6514 51.8411 44.4534 52.8046 45.3998C59.1885 51.6729 59.2762 61.9331 53.0031 68.317L29.5134 92.2186C23.2408 98.6002 12.9798 98.6899 6.59594 92.4171Z" fill="#57BE92"/>
    <path d="M18.1421 57.5508L41.2578 80.2687L29.513 92.2191C23.2397 98.6023 12.9795 98.6913 6.59629 92.418C0.213085 86.1448 0.124022 75.8845 6.3973 69.5013L18.1421 57.5508Z" fill="#EE6161"/>
    <path d="M6.59594 92.4164C6.47641 92.2999 6.36063 92.1797 6.24484 92.0592C6.15508 91.9664 6.06672 91.8689 5.98047 91.7721C12.242 95.472 20.4438 94.5881 25.7704 89.1677L37.8297 76.8984L41.2574 80.2657L29.5134 92.2179C23.2408 98.5995 12.9798 98.6892 6.59594 92.4164Z" fill="#E94444"/>
    <path d="M110.433 18.6616L80.8077 2.99804C76.5658 0.755302 71.7036 0.298504 67.1186 1.71225C62.533 3.126 58.7725 6.24061 56.5295 10.4828C54.2865 14.725 53.8297 19.5866 55.2435 24.1722C56.6572 28.7577 59.7718 32.5183 64.014 34.7612L93.6397 50.4248C93.9735 50.6012 94.3103 50.766 94.6499 50.9195C97.0262 51.9934 99.531 52.5058 102.006 52.5055C108.052 52.5055 113.918 49.4481 117.28 44.0505C117.504 43.6907 117.717 43.3204 117.918 42.94C122.548 34.1828 119.19 23.2916 110.433 18.6616ZM65.6586 31.6504C62.2473 29.8469 59.7428 26.823 58.6058 23.1355C57.4691 19.4481 57.8364 15.5387 59.6399 12.1276C61.4434 8.71655 64.4676 6.21179 68.155 5.07507C69.5608 4.64171 70.9982 4.42679 72.4286 4.42679C74.7503 4.42679 77.0521 4.9928 79.1626 6.1089L92.4201 13.1183L78.9161 38.6598L65.6586 31.6504ZM114.807 41.2951C111.084 48.3369 102.326 51.0365 95.2844 47.3139L82.0269 40.3044L95.5309 14.7632L108.788 21.7724C115.83 25.4955 118.53 34.2533 114.807 41.2951Z" fill="black"/>
    <path d="M42.1992 39.0093C41.2378 38.9695 40.4084 39.723 40.3681 40.694C40.3275 41.6648 41.0817 42.4846 42.0525 42.5252C45.6328 42.6745 49.0135 44.1412 51.5714 46.6553C54.3235 49.36 55.8575 52.9743 55.891 56.8331C55.9245 60.6916 54.4535 64.3321 51.7486 67.0842L41.2371 77.7796L20.6314 57.5282L31.1428 46.8325C32.2685 45.6871 33.56 44.7459 34.981 44.0348C35.85 43.6 36.2021 42.543 35.7671 41.6742C35.3325 40.8051 34.2755 40.4535 33.4064 40.8878C31.6374 41.7728 30.0314 42.9431 28.6329 44.366L5.14339 68.2671C0.153077 73.3448 -1.1995 80.6606 1.05776 86.9521C1.94112 89.414 3.37714 91.7191 5.36394 93.6719C5.57534 93.8798 5.7912 94.0818 6.01105 94.278C10.0758 97.9045 15.7013 99.5983 21.0619 98.5579C24.7475 97.8426 28.1246 96.1424 30.769 93.4516L54.2583 69.5507C57.6221 66.1284 59.4514 61.601 59.4099 56.8026C59.3682 52.0042 57.4606 47.5094 54.038 44.1459C50.8566 41.0191 46.6521 39.1949 42.1992 39.0093ZM28.2591 90.9848C25.5753 93.7157 21.8916 95.2713 18.0816 95.3043C14.2308 95.3303 10.5825 93.8667 7.8305 91.162C2.14948 85.5787 2.07003 76.4144 7.65355 70.7331L18.1648 60.0377L38.7706 80.2891C38.7706 80.2893 28.261 90.9829 28.2591 90.9848Z" fill="black"/>
    <path d="M67.6639 79.5945C62.8958 84.3626 60.4492 90.9572 60.9512 97.6873C61.0234 98.656 61.8636 99.3828 62.8365 99.3108C63.8057 99.2386 64.5325 98.3944 64.4603 97.4255C64.0344 91.718 66.1091 86.1258 70.1523 82.0826C77.8021 74.4328 90.2497 74.4328 97.8995 82.0826C105.549 89.7326 105.549 102.18 97.8995 109.83C94.1938 113.536 89.2668 115.577 84.0259 115.577C78.7853 115.577 73.8582 113.536 70.1525 109.83C68.6228 108.3 67.3672 106.551 66.4206 104.63C65.991 103.758 64.9363 103.4 64.0647 103.83C63.193 104.259 62.8347 105.314 63.2643 106.185C64.3813 108.452 65.8616 110.516 67.6642 112.318C72.0346 116.689 77.8454 119.095 84.0259 119.095C90.2064 119.095 96.0175 116.689 100.388 112.318C109.41 103.296 109.41 88.6165 100.388 79.5945C91.3654 70.5727 76.686 70.5727 67.6639 79.5945Z" fill="black"/>
    <path d="M91.6964 105.386C92.1466 105.386 92.5969 105.214 92.9407 104.87C93.6276 104.183 93.6276 103.069 92.9407 102.382L77.5973 87.0387C76.9101 86.3517 75.7962 86.3517 75.109 87.0387C74.422 87.7256 74.422 88.8398 75.109 89.5268L90.4523 104.87C90.7959 105.214 91.2464 105.386 91.6964 105.386Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_19673_24033">
    <rect width="120" height="120" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  }
  const renderList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const listItems = Array.from(doc.querySelectorAll('li'));
    if (listItems.length > 0) {
      const uTag = doc.querySelector('ul');
      return listItems.map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    } 
    return ReactHtmlParser(htmlString);
  }

  const navbarStyles = {
    dash: {
      color: "#96A591",
      fontStyle: "Lato"
    },
  };
  const getSectionArray = (originArray: ISectionData[]) => {
    const tempOriginArray = [...originArray]
    return tempOriginArray.splice(0, 4)
  }
  return (
    <>
     <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "10px",gap:"10px" , alignItems: 'center'}}>
      <ArrowBackIcon />
      <NavigationText sx={navbarStyles.dash}>Dashboard</NavigationText>
      <ChevronRightIcon sx={navbarStyles.dash} />
      <NavigationText sx={navbarStyles.dash}>Medications</NavigationText>
      <ChevronRightIcon sx={navbarStyles.dash} />
      <NavigationText>Pain</NavigationText>
      <ChevronRightIcon sx={navbarStyles.dash} />
      <NavigationText>Opioid Pain Meds</NavigationText>
      <ChevronRightIcon sx={navbarStyles.dash} />
      <NavigationText style={{fontWeight: "700", color: "#343C32"}}>Types of Opioid Pain Meds</NavigationText>
    </Box>
   <Container>
      <LatoText style={{fontWeight: "700", fontSize: 26, textAlign: 'center', padding: 10}}>
      <span style={{color: "#343C32"}}>Types of </span>
      <span style={{color: "#DC2626"}}>OPIOID PAIN</span>
      <span style={{color: "#343C32"}}> Medication</span>
      </LatoText>
      <Grid  container spacing={2}>
      {sectionList.length > 3 && getSectionArray(sectionList).map((section, index) => {
        return (
          <Grid xs={12} md={6} item>
      <ItemContainer>
          <LatoText style={{ fontWeight: "700", fontSize: 26, color: "#586554"}}>{extractTextNodeContent(section?.name)}</LatoText>
          {renderList(section.definition)}
      </ItemContainer>
      </Grid>)
      })}
   
      </Grid>
      {sectionList[4] && <ItemBottomContainer>
       <img style={{width: 120, height: 120}} src={sectionList[4].images[0].url}/>
       <div style={{display: "flex", flexDirection: 'column', gap: 12}}>
       {renderList(sectionList[4].definition)}
        </div>
        </ItemBottomContainer>}
   </Container>
   </>
  );
}
const Container = styled(Box)({
  display: 'flex', flexDirection: 'column', rowGap: 20, paddingLeft: 130, paddingRight: 130,
  '@media (max-width: 1180px)' : {
    paddingLeft: 16, paddingRight: 16,
    }
});
const ItemBottomContainer = styled(Box)({
  display: 'flex',flex: 1,marginBottom: 30, flexDirection: 'row', background: "#FEF2F2", padding: 40, paddingLeft: 20, paddingRight: 20,columnGap: 20, borderRadius: 8, border: "2px dashed #F87171",
  '@media (max-width: 1180px)' : {
    flexDirection: 'column',
    rowGap: "35px"
    }
});
const ItemContainer = styled(Box)({
  display: 'flex',flex: 1, flexDirection: 'column', background: "#F2F4F1", height: 240, padding: 10, paddingLeft: 20, paddingRight: 20, gap: 16, borderRadius: 8,
  '@media (max-width: 1180px)' : {
    height: 'auto'
    }
});

const RowItemTop = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: 10
});
const RowItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: 10
});
const LatoText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400"
});
const DescriptionText = styled(Typography)({
  fontFamily: "Lato",
  color: "#343C32",
  fontWeight: "400",
  fontSize: 22,
});
const NavigationText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
  fontSize: 22,
});

