import React from 'react';
import { Typography, Box, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const InlineFlexContainer = styled(Box)({
  display: "inline-flex",
  alignItems: "center",  
});

const MainContainer = styled(Box)(({ theme }) => ({ 
  marginTop: "10px", 
  marginBottom: "10px",
  display: "flex", 
  alignItems: 'center',
  gap: 0,
  [theme.breakpoints.up("lg")]: {
    gap: "10px",
    minWidth: '512px',
  },
}));

const BreadcrumbElement = styled(Button)(({ theme }) => ({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
  textTransform: 'none',
}));

const PreviousLevelText = styled(Typography)({
  fontFamily: "Lato",
  color: "#96A591",
  fontWeight: "400",
});

const LocationText = styled(PreviousLevelText)({
  color: "#343C32",
  fontWeight: "700",
  padding: '4px 8px',
});

const Seperator = styled(ChevronRightIcon)({
  color: "#96A591",
})

const BackIcon = styled(ArrowBackIcon)({
  color: "#343C32",
})

type IContentBreadcrumbsProps = {
  stacks: string;
  onNavigate: (to: string) => void;
}

const ContentBreadcrumbs = ({ stacks, onNavigate }: IContentBreadcrumbsProps) => (
  <MainContainer>
    <IconButton onClick={() => onNavigate('')}>
      <BackIcon sx={{ fontSize: { xs: 16, lg: 24 } }} />
    </IconButton>
    <InlineFlexContainer sx={{ flexWrap: 'wrap' }}>
      {stacks !== '' && stacks.split('/').map((stack, index) => (
        <InlineFlexContainer key={index} sx={{ gap: '2px' }}>
          {index < (stacks.split('/').length - 1) ? 
            <BreadcrumbElement onClick={() => onNavigate(stack)}>
              <PreviousLevelText sx={{ fontSize: { xs: 12, lg: 22 } }}>{stack}</PreviousLevelText>
            </BreadcrumbElement> : 
            <LocationText sx={{ fontSize: { xs: 12, lg: 22 } }}>
              {!stack.includes('-') ? stack : stack.split('-').join('/')}
            </LocationText>}
          {index < (stacks.split('/').length - 1) && <Seperator sx={{ fontSize: { xs: 14, lg: 24 } }} />}
        </InlineFlexContainer>
      ))}
    </InlineFlexContainer>
  </MainContainer>
);

export default ContentBreadcrumbs;
