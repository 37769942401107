import React, { useEffect, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import SymptomOverviewSection from "./SymptomOverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import ItemWithAnnotation from "./ItemWithAnnotation.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { OverviewAnnotation } from "./MethodOverviewSection.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const OmbreContainer = styled(Box)({
  padding: '20px',
  borderRadius: '8px',
  display: 'flex',
  justifyItems: "flex-start"
})

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  fontSize: '22px', 
  fontWeight: '400',
});

interface ISymtomWithRelativeCausesListData {
  headings: string[];
  rows: { symptom: Pick<ISectionData, 'definition' | 'images'>; cause: Pick<ISectionData, 'definition' | 'images'> }[];
}

const UrinaryProblems = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);
  const [symtomWithRelativeCausesList, setSymtomWithRelativeCausesList] = useState<ISymtomWithRelativeCausesListData>({ headings: [], rows: [] });

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  useEffect(() => {
    if (sectionList.some(section => extractTextNodeContent(section.name) === 'Common Symptoms') && 
      sectionList.some(section => extractTextNodeContent(section.name) === 'Common Causes')) {
      const symptoms = sectionList.find(section => extractTextNodeContent(section.name) === 'Common Symptoms');
      const relativeCauses = sectionList.find(section => extractTextNodeContent(section.name) === 'Common Causes');
      if (symptoms && relativeCauses && symptoms?.children && relativeCauses?.children && symptoms?.children.length === relativeCauses?.children.length) {
        const first = symptoms?.children || [];
        const second = relativeCauses?.children || [];
        const rows = first.map((symptom, index) => ({ symptom, cause: second[index] }));
        setSymtomWithRelativeCausesList({ headings: ['Common Symptoms', 'Common Causes'], rows })
      }
    }
  }, [sectionList]);

  const renderItemsWithChildren = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return (
      <ItemWithAnnotation
        withOrdering={doc.querySelector('p:has(strong)')?.previousElementSibling && doc.querySelector('p:has(strong)')?.previousElementSibling?.tagName === 'P' || false}
        order={doc.querySelector('p:has(strong)')?.previousElementSibling?.textContent || ''}
        orderStyles={{ backgroundColor: 'transparent', padding: '0', width: '62px', height: '62px' }}
        main={doc.querySelector('p:has(strong)')?.textContent || ''}
        annotationList={Array.from(doc.querySelectorAll('li')).map((li, index) => <PlainTextItem key={index}>{ReactHtmlParser(li.innerHTML)}</PlainTextItem>)}
        containerStyles={{ flex: 1, alignItems: 'start' }} 
      />
    )
  }
  const renderListItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.querySelectorAll('li')).map((li, index) => (<PlainTextItem key={index}>{ReactHtmlParser(li.innerHTML)}</PlainTextItem>))
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Urinary Problems' onNavigate={onNavigate} />
      <SectionListLayout>
        <SymptomOverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && <SectionTitleContainer>{ReactHtmlParser(title?.title)}</SectionTitleContainer>} 
          shortDesc={title && <LightSectionSubtitle>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
        />
        <CustomSectionContainer contentStyle={{ flexDirection: 'row', flexWrap: 'wrap', gap: '34px' }}>
          {sectionList
            .filter(section => extractTextNodeContent(section.name) === 'Supplies to have' || extractTextNodeContent(section.name) === 'Common issues')
            .map((section, index) => 
              <OverviewAnnotation
                key={index}
                layoutStyle={{ alignItems: 'center' }}
                title={ReactHtmlParser(section.name)}
                image={section.images.length > 0 ? section.images[0]?.url : undefined}
                contentStyle={{ gap: '12px' }}
                content={renderListItems(section.definition)}
              />)}
        </CustomSectionContainer>

        <CustomSectionContainer>
          <Grid container>
            {symtomWithRelativeCausesList.headings.map((heading, index) => 
              <Grid item xs={6} key={index}><LightSectionTitle textAlign="center">{heading}</LightSectionTitle></Grid>)}
          </Grid>
          {symtomWithRelativeCausesList.rows.map((row, index) => 
            <OmbreContainer key={index} sx={{ backgroundColor: (index + 1) % 3 === 0 ? '#A7B3A3' : (index + 1) % 2 === 0 ? '#C3CBC0' : '#D6DCD5' }}>
              {renderItemsWithChildren(row.symptom.definition)}
              <Divider orientation="vertical" variant="middle" flexItem sx={{ border: 'border: 1px solid #A7B3A3' }}/>
              {renderItemsWithChildren(row.cause.definition)}
            </OmbreContainer>
          )}
        </CustomSectionContainer>
        {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
            .filter(section => extractTextNodeContent(section.name) === 'What To Do' || extractTextNodeContent(section.name) === 'When to Call the Hospice')
            .map((section, index) =>  
              <CustomSectionContainer
                key={index}
                withHeading
                heading={<LightSectionTitle>{ReactHtmlParser(section.name)}</LightSectionTitle>}
                containerStyle={extractTextNodeContent(section.name) === 'When to Call the Hospice' ? { backgroundColor:  '#FEF2F2', border: '2px dashed #F87171' } : undefined} 
              >
                <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12} md={6}>
                      <ItemWithImage image={child.images[0]?.url || ''} text={ReactHtmlParser(child.definition)} />
                    </Grid>
                  ))}
                </Grid>
              </CustomSectionContainer>)}
      </SectionListLayout>
    </>
  );
}

export default UrinaryProblems;
