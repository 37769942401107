import React, { CSSProperties, ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled(Box)({
  backgroundColor: "#F2F4F1", 
  color: '#343C32',
  borderRadius: "8px", 
});

const SubtitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  fontFamily: "Lato",
  fontWeight: '400', 
  fontSize: '22px', 
  color: '#586554'
});

const DescImg = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'contain'
});

type ISymptomOverviewSectionProps = {
  images: string[];
  name: ReactNode;
  shortDesc: ReactNode;
  annotation?: ReactNode;
  containerStyle?: CSSProperties; 
}

const SymptomOverviewSection = (props: ISymptomOverviewSectionProps) => (
  <MainContainer sx={{ padding: { xs: "40px 16px", lg: '40px 130px' }, ...props.containerStyle }}>
    <Grid container alignItems="center" spacing={2}>
      {props.images.length > 0 && <Grid item xs={12} sm={2} container justifyContent="center">
        <DescImg src={props.images[0]} alt='Left image' />
      </Grid>}
      <Grid item xs={12} sm={props.images.length > 0 ? 8 : 12}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, textAlign: 'center' }}>
          {props.name}
          {props.shortDesc && <SubtitleContainer>{props.shortDesc}</SubtitleContainer>} 
        </Box>
      </Grid>
      {props.images.length > 0 && <Grid item xs={12} sm={2} container justifyContent="center">
        <DescImg src={props.images[1]} alt='Right image' />
      </Grid>}
    </Grid>
    {props.annotation && <Box mt={5}>{props.annotation}</Box>}
  </MainContainer>
);

export default SymptomOverviewSection;
