// Customizable Area Start
import React, { CSSProperties } from "react";
import { Box } from '@mui/material';
import { styled } from '@mui/system';
// Customizable Area End

// Customizable Area Start
import ContentManagementController, {
  Props,
} from "./ContentManagementController";

import MedicationLaxatives from "../../../components/src/MedicationLaxatives"
import NauseaVomiting from "../../../components/src/NauseaVomiting"
import Non_opioid_pain from "../../../components/src/Non_opioid_pain";
import StopMedication from "../../../components/src/StopMedication";
import AuthorizedHeader from "../../../components/src/AuthorizedHeader"
import OpioidMyths from "../../../components/src/OpioidMyths";
import MedicationPain from "../../../components/src/MedicationPain"
import TypesOpioidMed from "../../../components/src/TypesOpioidMeds"
import ShortnessOfBreadth from "../../../components/src/ShortOfBreadth";
import AbbeyPainScale from "../../../components/src/AbbeyPainScale";
import Psychotropic from "../../../components/src/Psychotropic.web";
import Diarrhea from "../../../components/src/Diarrhea";
import SymptomARD from "../../../components/src/SymptomARD.web";
import RecipeForMMIC from "../../../components/src/RecipeForMMIC.web";
import UrinaryProblems from "../../../components/src/UrinaryProblems.web";
import Sleep from "../../../components/src/Sleep.web";
import BelchingAndHiccups from "../../../components/src/BelchingAndHiccups.web";
import Skincare from "../../../components/src/Skincare.web";
import DryMouthCare from "../../../components/src/DryMouthCare.web";
import HospiceExpectation from "../../../components/src/HospiceExpectation.web";
import DiscussionTopics from "../../../components/src/DiscussionTopics.web";
import NecessarySupplies from "../../../components/src/NecessarySupplies.web";
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return <AuthorizedHeader
      navigation={this.props.navigation}
      data-test-id={"authorized-header"}
    />
  }

  renderContentManagement = () => {
    if (!this.state.contentData.attributes.title.layout_id) return;
    const layoutId = this.state.contentData.attributes.title.layout_id || '';

    const componentByLayoutId: { [key: string]: JSX.Element } = {
      "11": <MedicationPain />,
      "111": <TypesOpioidMed data={this.state.contentData}  onNavigate={this.handleNavigate} />,
      "112": <Non_opioid_pain />,
      "12": <OpioidMyths data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "13": <NauseaVomiting data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "14": <StopMedication data={this.state.contentData} />,
      "15": <MedicationLaxatives title="How will this medication help" />,
      "16": <Psychotropic data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "210": <Skincare data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "211": <AbbeyPainScale />,
      "2111": <DryMouthCare data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "22": <SymptomARD data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "23": <ShortnessOfBreadth />,
      "241": <Diarrhea />,
      "261": <RecipeForMMIC data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "27": <UrinaryProblems data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "28": <Sleep data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "29": <BelchingAndHiccups data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "41": <HospiceExpectation data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "42": <NecessarySupplies data={this.state.contentData} onNavigate={this.handleNavigate} />,
      "43": <DiscussionTopics data={this.state.contentData} onNavigate={this.handleNavigate} />,
    };

    return componentByLayoutId[layoutId] || 'There are no screens with this layout id';
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Body sx={{ padding: { xs: 0, lg: 2.5 } }}>
        {this.renderHeader()}
        {this.renderContentManagement()}
      </Body>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const Body = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  background: '#FAF9F6',
});
// Customizable Area End
