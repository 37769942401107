import { cloneElement, createElement, CSSProperties, isValidElement, ReactElement, ReactNode } from 'react';

type ReactElementOrString = ReactElement | string;

interface HTMLReactParserOptions {
    replace?: (
      domNode: Node,
      index: number,
    ) => JSX.Element | string | null | boolean | object | void;
  
    transform?: (
      reactNode: ReactNode,
      domNode: Node,
      index: number,
    ) => JSX.Element | string | null | void;
  
    trim?: boolean;
  }

const returnFirstArg = (arg: any) => arg;

const styleStringToObject = (style: string): CSSProperties => {
    return style
        .split(';')                // Split the string by semicolons
        .filter(Boolean)           // Remove empty strings
        .map(style => style.trim())// Trim whitespace from each string
        .reduce((acc, style) => {
            const [property, value] = style.split(':').map(part => part.trim());
            const camelCaseProperty = property.replace(/-([a-z])/g, g => g[1].toUpperCase());
            // @ts-ignore        
            acc[camelCaseProperty as keyof CSSProperties] = value;
            return acc;
        }, {} as CSSProperties);
  };

const ReactHtmlParser = (html: string, options?: HTMLReactParserOptions): ReactElementOrString[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const nodesLength = doc.body.childNodes.length;
  const hasReplace = options && options?.replace && typeof options?.replace === 'function';
  const transform = (options && options.transform) || returnFirstArg;

  const traverseNodes = (node: Node, index: number): ReactElementOrString => {
    if (hasReplace) {
      // return options.replace!(node, index) as JSX.Element;
      let replaceElement = options.replace!(node, index) as JSX.Element;

      if (isValidElement(replaceElement)) {
        // set "key" prop for sibling elements
        if (nodesLength > 1) {
          replaceElement = cloneElement(replaceElement, {
            key: replaceElement.key || index,
          });
        }

        return transform(replaceElement, node, index);
      }
    }
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent ?? '';
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      const children = Array.from(element.childNodes).map(traverseNodes);
      const props: { [key: string]: any } = {};

      // Set element attributes as props if needed
     Array.from(element.attributes).forEach(attr => {
        if (attr.name === 'style') {
            props.style = styleStringToObject(attr.value);
        } else {
            props[attr.name] = attr.value;
        }
      });

      return createElement(element.tagName.toLowerCase(), props, ...children);
    }
    return '';
  };

  return Array.from(doc.body.childNodes).map(traverseNodes);
}

const isContainingOnlyOnePTag = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const pTags = doc.querySelectorAll('p');
  return pTags.length === 1;
}

export const extractTextNodeContent = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const getTextNodesCombined = (element: HTMLElement) => {
    let combinedText = '';
    // Iterate over child nodes
    element.childNodes.forEach((node: ChildNode) => combinedText += node.textContent);
    return combinedText;
  }

  if (!isContainingOnlyOnePTag(html)) {
    return html;
  }

  const pTag = doc.querySelector('p');

  if (pTag) {
    return getTextNodesCombined(pTag);
  } else {
    return html;
  }
}

export default ReactHtmlParser