import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import SymptomOverviewSection from "./SymptomOverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import PlainTextItem from './PlanTextItem.web';
import ItemWithLetter from "./ItemWithLetter.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  fontSize: '22px', 
  fontWeight: '400',
});

const RecipeForMMIC = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          if (group[1][0]?.definition === "") {
            return {
              name: group[0],
              definition: '',
              images: group[1][0]?.images,
              children: group[1].slice(1).map(each => {
                return { definition: each.definition, images: each.images }
              })
            }
          }
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  const renderList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    if (doc.body.children.length === 1) {
      if (doc.body.children[0].tagName === 'OL') {
        return Array.from(doc.querySelectorAll('li')).map((item, index) => 
          (<ItemWithLetter
            key={index}
            containerStyles={{ padding: '12px 20px', backgroundColor: '#FFF', borderRadius: '8px' }}  
            orderContainerStyles={{ padding: '0', backgroundColor: '#F2F4F1', fontSize: '26px' }}  
            content={[item.innerHTML]}
            letter={(index + 1).toString()}
          />))
      }
      return Array.from(doc.querySelectorAll('li')).map((item, index) => (<PlainTextItem key={index}>{ReactHtmlParser(item.innerHTML)}</PlainTextItem>))
    }
  }

  return (
    <>
      <ContentBreadcrumbs stacks='Dashboard/Symptoms/Appetite and Food/Recipe for Mini Mint Ice Cubes' onNavigate={onNavigate} />
      <SectionListLayout>
        <SymptomOverviewSection 
           images={title ? title?.images.map(image => image.url) : []} 
           name={title && <SectionTitleContainer>{ReactHtmlParser(title?.title)}</SectionTitleContainer>} 
           shortDesc={title && <LightSectionSubtitle>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
        />
        {sectionList.map((section, index) =>
          <CustomSectionContainer
            key={index} 
            withHeading 
            heading={<LightSectionTitle>{ReactHtmlParser(section.name)}</LightSectionTitle>}
            contentStyle={{ flexDirection: section.images.length > 0 ? 'row' : undefined }}
          >
            {section.children && section.children.length > 0 ? 
              <>
                {section.images.length > 0 ? <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  {section.children.map((child, index) => 
                    <ItemWithImage key={index} text={ReactHtmlParser(child.definition)} image={child.images[0]?.url || ''} />)}
                </Box> : <>
                  {section.children.map((child, index) => 
                    <ItemWithImage key={index} text={ReactHtmlParser(child.definition)} image={child.images[0]?.url || ''} />)}
                </>}
                {section.images.length > 0 && <Box sx={{ flex: 1 }}>
                  <img src={section.images[0]?.url} alt="Description Image" style={{ width: '100%', height: '100%' }}/>
                </Box>}
              </> : renderList(section.definition)
            }
           
          </CustomSectionContainer> 
        )}
      </SectionListLayout>
    </>
  );
}
export default RecipeForMMIC;
