import React, { CSSProperties, isValidElement } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/system';

import ReactHtmlParser from './ReactHtmlParser';

const Container = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '20px',
})

const Letter = styled(Avatar)({
  backgroundColor: '#D6DCD5', 
  borderRadius: '8px', 
  padding: '10px', 
  color: '#586554', 
  fontFamily: 'Lato', 
  fontSize: '36px', 
  fontWeight: '700'
})

const ContentContainer = styled(Box)({
  padding: '0 20px', 
  display: 'flex', 
  flexDirection: 'column', 
})

const NoticeContainer = styled(Box)({
  backgroundColor: '#F7E2E2',
  padding: '10px 20px', 
  borderRadius: '8px',
  border: '2px dashed #F87171',
  cursor: 'pointer'
})

const NormalText = styled(Typography)({
  fontSize: '22px', 
  fontWeight: '400',
  fontFamily: 'Lato',
  color: '#343C32'
});

type IItemWithLetterProps = {
  letter: string;
  content: string[];
  containerStyles?: CSSProperties;
  orderContainerStyles?: CSSProperties;
}

const ItemWithLetter = (props: IItemWithLetterProps) => (
  <Container sx={{ ...props.containerStyles }}>
    <Letter sx={{ ...props.orderContainerStyles }}>{props.letter}</Letter>
    <ContentContainer sx={{ gap: 2.5 }}>
      {props.content.map((each, index) => {
        const element = ReactHtmlParser(each);
        if (element.some(tag => isValidElement(tag) && tag.type === 'a')) {
          return <NoticeContainer key={index}>
              <NormalText>{element}</NormalText>
            </NoticeContainer>
        }
        return <NormalText key={index}>{element}</NormalText>
      })}
    </ContentContainer>
  </Container>
);

export default ItemWithLetter;
