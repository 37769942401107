import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { styled } from '@mui/system';

import ContentBreadcrumbs from "./ContentBreadcrumb.web";
import SymptomOverviewSection from "./SymptomOverviewSection.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";
import { ISectionData } from "./SymptomARD.web";
import ReactHtmlParser, { extractTextNodeContent } from "./ReactHtmlParser";

import { IContentData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const SectionTitleContainer = styled(Box)({
  fontSize: '26px', 
  fontWeight: '700',
  fontFamily: 'Lato',
  color: '#343C32',
  '& p': {
    margin: 0
  }
});

const LightSectionTitle = styled(SectionTitleContainer)({
  color: '#586554',
});

const LightSectionSubtitle = styled(LightSectionTitle)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  fontSize: '22px', 
  fontWeight: '400',
});

const NecessarySupplies = ({ data, onNavigate }: { data: IContentData, onNavigate: (to: string) => void }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);

  useEffect(() => {
    if (terms) {
      terms.sort((a, b) => a.id - b.id); 
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,
        }
      })
      setSectionList(sections)
    }
  }, [terms]);

  return (
    <Box>
      <ContentBreadcrumbs stacks='Dashboard/The first week in Hospice/Supplies you may need' onNavigate={onNavigate} />
      <SectionListLayout styles={{ gap: '10px' }}>
        <SymptomOverviewSection 
          images={title ? title?.images.map(image => image.url) : []} 
          name={title && <SectionTitleContainer>{ReactHtmlParser(title?.title)}</SectionTitleContainer>} 
          shortDesc={title && title?.desc && title?.desc !== '' &&
            <LightSectionSubtitle>{ReactHtmlParser(title?.desc)}</LightSectionSubtitle>}
        />
        {sectionList.length > 0 && sectionList.map((section, index) => (
          <CustomSectionContainer 
            key={index} 
            withHeading={!!section.name && extractTextNodeContent(section.name).length > 1}
            heading={<LightSectionTitle>{ReactHtmlParser(section.name)}</LightSectionTitle>}
          >
            <Grid container spacing={2.5}>
              {section && section?.children && section?.children.map((child, childIndex) => (
                <Grid key={childIndex} item xs={12} md={index % 2 === 0 && 6}>
                  <ItemWithImage key={`grid-item-${childIndex}`} image={child.images[0].url || ''} text={ReactHtmlParser(child.definition)} />
                </Grid>
              ))}
            </Grid>
          </CustomSectionContainer>
        ))}
      </SectionListLayout>
    </Box>
  );
}

export default NecessarySupplies;
